<template>
  <AppCard sheet centered>
    <div class="loading-container pt-14 md:pt-0">
      <div class="overlay-container relative">
        <img
          class="center-icon"
          width="32"
          height="32"
          src="@galileo/assets/images/activity-in-progress.svg"
          alt="Flying money"
        />
        <AppSpinnerBig :loading="true" :inline="true" />
      </div>
    </div>
    <h2 v-if="isO2OContract" class="type-h2 mb-4 text-center text-primary-text">
      {{ $t('PageSendMoneyCreating.UpdatingDescription').value }}
    </h2>
    <h2 v-else class="type-h2 mb-4 text-center text-primary-text;">
      {{ $t('PageSendMoneyCreating.CreatingDescription').value }}
    </h2>
  </AppCard>
</template>

<script>
import { AppCard, AppSpinnerBig } from '@oen.web.vue2/ui'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  components: {
    AppCard,
    AppSpinnerBig,
  },
  setup() {
    const { isO2OContract } = useSendMoneyStore()
    const { $t } = useI18nStore()

    return { isO2OContract, $t }
  },
}
</script>

<style scoped>
.loading-container {
  @apply flex justify-center mb-6;
  margin-top: 100px;
  @screen smL {
    margin-top: 0px;
  }
}

.overlay-container > img,
svg {
  @apply absolute;
}

.overlay-container > .loading-spinner {
  @apply w-auto;
}

::v-deep .overlay-container > .loading-spinner > .loading-spinner-container > svg {
  width: 5rem;
  height: 5rem;
}

.center-icon {
  position: absolute;
  top: 40%;
  left: 40%;
}
</style>
