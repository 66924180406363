<template>
  <AppCard sheet centered>
    <div class="loading-container pt-14 md:pt-0">
      <div class="overlay-container relative">
        <img
          src="@galileo/assets/images/bank/OpenBanking.svg"
          alt="Volt logo"
          class="center-icon"
        />
        <AppSpinnerBig :loading="true" :inline="true" />
      </div>
    </div>
    <h2 class="type-h2 mb-4 text-center text-primary-text">
      {{ $t('SendMoneyCreating.VoltRedirectTitle').value }}
    </h2>
    <p
      class="text-center text-tertiary-text"
      v-html="$t('SendMoneyCreating.VoltRedirectInstructions').value"
    />
  </AppCard>
</template>

<script>
import { AppCard, AppSpinnerBig } from '@oen.web.vue2/ui'

import { useI18nStore } from '@galileo/stores'
import { IconVolt, IconPayTo } from '@galileo/assets/icons/vue'

export default {
  components: {
    AppCard,
    AppSpinnerBig,
    IconVolt,
  },
  setup() {
    const { $t } = useI18nStore()

    return { $t }
  },
}
</script>

<style scoped>
.loading-container {
  @apply flex justify-center mb-6;
  margin-top: 100px;
  @screen smL {
    margin-top: 0px;
  }
}

.overlay-container {
  @apply relative -mb-10;
}

.overlay-container > img,
svg {
  @apply absolute;
}

.overlay-container > .loading-spinner {
  @apply w-auto;
}

::v-deep .overlay-container > .loading-spinner > .loading-spinner-container > svg {
  @apply w-20 h-20;
}

.center-icon {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 20px);
}

.text-primary-text,
.text-tertiary-text {
  position: relative;
  z-index: 51;
}
</style>
