<template>
  <!-- Content -->
  <LayoutCenter>
    <AppAlert
      v-if="adyenHosted3dsLoading || (!voltCheckoutComplete && isOpenBanking && !isPayTo)"
      theme="yellow"
      class="open-banking-warning"
    >
      <p>⚠️ {{ $t('SendMoneyCreating.VoltRedirectWarning').value }}</p>
    </AppAlert>
    <!--Loading-->

    <AppSpinnerBig :loading="cardAuthLoading" />

    <!--Threat Metrix-->
    <iframe title="ThreatMetrix" class="threatmetrix" :src="threatMetrixSrc"></iframe>

    <!--Creating Transfer-->
    <Transition name="fade">
      <AppCardSendMoneyCreatingConvert v-if="routeFrom === 'ConvertBalances'" />
      <AppCardSendMoneyCreatingFunding v-else-if="routeFrom === 'FundBalance'" />
      <AppCardSendMoneyCreating
        v-else-if="fadeIn && (!isOpenBanking || isPayTo || (voltCheckoutComplete && isOpenBanking))"
      />
      <!-- Component for Volt Redirection-->
      <AppCardVoltRedirect
        v-else-if="
          adyenHosted3dsLoading || (fadeIn && !voltCheckoutComplete && isOpenBanking && !isPayTo)
        "
      />
    </Transition>

    <!-- Card authentication dialog -->
    <AppModal
      v-show="!adyenHosted3dsLoading"
      v-model="cardAuthDialog"
      :class="{
        'card-auth-dialog': true,
        'card-auth-dialog-visible': cardAuthDialogVisible,
        'card-auth-dialog-blurred': cardAuthLoading,
      }"
      :title="$t('PageSendMoneyCreating.CardAuthHeader').value"
    >
      <template #header>
        <AppModalHeader>
          <h3>{{ $t('PageSendMoneyCreating.CardAuthHeader').value }}</h3>
          <template #right>
            <AppBackButton analytics-name="send-money-creating-back" @click="hideCardAuthDialog" />
          </template>
        </AppModalHeader>
      </template>

      <div id="card_auth_container"></div>
      <AppSpinnerBig :loading="!cardAuthorizationLoaded" />
      <iframe
        v-if="cardAuthFields && cardAuthFields.length > 0"
        id="card_auth_iframe"
        title="Card authentication"
        name="card_auth_iframe"
        class="card-auth-iframe"
      >
      </iframe>
      <form
        v-if="cardAuthFields && cardAuthFields.length > 0"
        id="card_auth_post"
        :action="cardAuthUrl"
        target="card_auth_iframe"
        method="post"
      >
        <input
          v-for="(field, idx) in cardAuthFields"
          :key="idx"
          type="hidden"
          :name="field.key"
          :value="field.value"
        />
      </form>
    </AppModal>
  </LayoutCenter>
</template>

<script>
import {
  ref,
  computed,
  onBeforeMount,
  nextTick,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import LayoutCenter from '@galileo/components/LayoutCenter/LayoutCenter'
import { PaymentMethod } from '@galileo/models/Transaction/app'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { TRANSFER, PAYMENT_METHODS, TRANSFER_TYPE } from '@galileo/constants/sendMoneyFlow.const'
import AppCardSendMoneyCreating from '@galileo/components/Views/SendMoney/SendMoneyCreating/AppCardSendMoneyCreating.vue'
import AppCardSendMoneyCreatingFunding from '@galileo/components/Views/SendMoney/SendMoneyCreating/AppCardSendMoneyCreatingFunding.vue'
import AppCardVoltRedirect from '@galileo/components/Views/SendMoney/SendMoneyCreating/AppCardVoltRedirect.vue'
import AppCardSendMoneyCreatingConvert from '@galileo/components/Views/SendMoney/SendMoneyCreating/AppCardSendMoneyCreatingConvert.vue'

import {
  AppFigure,
  AppCard,
  AppCardImageTitle,
  AppModal,
  AppModalHeader,
  AppSpinnerBig,
  AppBackButton,
  AppIcon,
  AppAlert,
} from '@oen.web.vue2/ui'

import useAdyen from '@galileo/composables/useAdyen/index.ts'

import { IconVolt, IconPayTo } from '@galileo/assets/icons/vue'
import useOpenBanking from '@galileo/composables/useOpenBanking'

import {
  useI18nStore,
  useCardAccountStore,
  useEnvStore,
  usePaymentsStore,
  useDeviceStore,
  useAnalyticsStore,
  useActivityStore,
  useAppStore,
  useSendMoneyStore,
  useAuthStore,
  storeToRefs,
} from '@galileo/stores'
import { PAYMENT_GATEWAY } from '@galileo/constants/cardPayments.const'

export default {
  name: 'SendMoneyCreating',
  components: {
    AppBackButton,
    AppCard,
    AppCardImageTitle,
    AppModal,
    AppModalHeader,
    AppSpinnerBig,
    LayoutCenter,
    AppFigure,
    AppIcon,
    IconVolt,
    IconPayTo,
    AppAlert,
    AppCardSendMoneyCreating,
    AppCardSendMoneyCreatingConvert,
    AppCardVoltRedirect,
    AppCardSendMoneyCreatingFunding,
  },
  props: {},
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const envStore = useEnvStore()
    const paymentsStore = usePaymentsStore()
    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const analyticsStore = useAnalyticsStore()
    const cardAccount = useCardAccountStore()
    const activityStore = useActivityStore()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()

    const { voltCheckoutComplete } = storeToRefs(sendMoneyStore)

    let adyenComposable = null

    const {
      isPayTo,
      isOpenBanking,
      addTabListener,
      shouldUseOpenBankingFlow,
      handleOpenBankingTransfer,
      closeBroadcastChannel,
    } = useOpenBanking(router)

    const fadeIn = ref(false)

    window.setTimeout(() => {
      fadeIn.value = true
    }, 100)

    const senderCountry = authStore.userProfile?.country

    const routeFrom = appStore.getCurrentRoute.from.name

    const isO2OContract = sendMoneyStore.isO2OContract
    const sessionId = deviceStore.getId
    sendMoneyStore.form.sessionId = sessionId

    const threatMetrix = {
      url: envStore.env.VUE_APP_THREAT_METRIX_TAGS_URL,
      credentials: envStore.env.VUE_APP_THREAT_METRIX_CREDENTIALS,
      pageId: '1',
    }

    const threatMetrixSrc = `${threatMetrix.url}?org_id=${encodeURIComponent(
      threatMetrix.credentials
    )}&session_id=${encodeURIComponent(sessionId)}&pageid=${encodeURIComponent(
      threatMetrix.pageId
    )}`

    const threeDSActionType = ref('')

    const orderCreate = async () => {
      let result = null
      try {
        sendMoneyStore.quoteExpired = false
        sendMoneyStore.clearRefreshQuote()
        cardAccount.clearCardInformation()

        if (shouldUseOpenBankingFlow()) {
          result = await handleOpenBankingTransfer()
          //if null we halt the transfer
          if (!result) return
        } else {
          result = await sendMoneyStore.orderCreate()
        }
      } catch (ex) {
        onError(ex)
      }
      if (result) {
        if (result.success) {
          const paymentMethod = sendMoneyStore.form.paymentMethod

          if (paymentMethod === 'PayNearMe') {
            // TODO IMPROVE ?
            router.push('/send-money/fund')
          } else {
            router.push('/send-money/finished')
          }

          try {
            // update last transaction
            await activityStore.getTransactions()

            //we send review transaction segment  only if is O2O contract
            if (isO2OContract) {
              // Segment report created transaction
              sendMoneyStore.transactionCreatedSegmentEvent(
                SEGMENT_EVENTS.REVIEW_TRANSACTION_SUMMARY_CONFIRMED
              )
            }
          } catch (ex) {
            appStore.logException(
              'Exception during getting last transactions after order created',
              ex
            )
            appStore.messageBoxGenericError()
          }
        } else if (result.nextPage) {
          router.replace(result.nextPage)
        } else {
          if (result.nextPage) {
            return router.replace(result.nextPage)
          }

          analyticsStore.track({
            event: SEGMENT_EVENTS.PAYMENT_FAILED,
            traits: {
              paymentType: sendMoneyStore.form.paymentMethod,
              errorCode: result.code, //e.g. "Card.CardHolderLastName: The CardHolderLastName field is required."
              errorDesc: result.message, // e.g. "Bad Request Format: Card.CardHolderLastName: The CardHolderLastName field is required.",
              newCard: cardAccount.getIsNewCard(), // e.g. true,
              cardBrand: cardAccount.cardBrand,
              cardBillingCountry: cardAccount.cardBillingCountry, // e.g. AU,
              paymentProvider: authStore.userProfile.customer.cardPaymentGateway, //e.g "Adyen”,
              location: SEGMENT_LOCATIONS.SEND_MONEY,
            },
          })
          router.replace('/send-money/failed')
        }
      } else {
        /* TODO LOGGING */
        router.replace('/send-money/failed')
      }
    }

    let authenticated = false
    const cardAuthDialog = ref(false)
    const cardAuthDialogVisible = ref(false)
    const cardAuthLoading = ref(false)
    const cardAuthFields = ref([])
    const cardAuthUrl = ref('')
    const cardAuthorizationLoaded = ref(false)
    const selectedPaymentMethod = paymentsStore.selectedPaymentMethod

    const handleMissingTransactionInfo = (missingTransactionInfo, validated) => {
      if (
        missingTransactionInfo === 'RequiresPayerAuthenticationCheck' &&
        validated.payerAuthenticateResponse
      ) {
        // TODO will probably be deprecated soon when 3DSV2 is used
        const authData = validated.payerAuthenticateResponse
        let termUrl = `${envStore.env.VUE_APP_ENDPOINTS_RMTAPI}account/card/payer-authentication-post`
        // relative URL needs to be converted to an absolute URL otherwise Cardinal will treat it as an incorrect relative Cardinal URL
        // (i.e. https://merchantacsstag.cardinalcommerce.com/api/account/card/payer-authentication-post)
        if (termUrl.indexOf('/') === 0) {
          termUrl = window.location.protocol + '//' + window.location.host + termUrl
        }

        cardAuthDialog.value = true
        cardAuthUrl.value = authData.acsUrl
        cardAuthFields.value = [
          { key: 'acsUrl', value: authData.acsUrl },
          { key: 'MD', value: authData.xid },
          { key: 'PaReq', value: authData.paReq },
          { key: 'TermUrl', value: termUrl },
        ]

        // TODO IMPROVE
        const checkCardAuthResult = async () => {
          try {
            const result = await cardAccount.verifyCardAuthentication(authData.xid)
            if (result) {
              sendMoneyStore.form.cardAuthenticationResult = result
              authenticated = true
              cardAuthDialog.value = false
              cardAuthLoading.value = false
              await orderCreate()
            } else {
              window.setTimeout(() => {
                checkCardAuthResult()
              }, 500)
            }
          } catch (ex) {
            sendMoneyStore.isPaymentMethodError = true
            appStore.logException('Exception during card authentication', ex)
            analyticsStore.track({
              event: SEGMENT_EVENTS.PAYMENT_FAILED,
              traits: {
                paymentType: sendMoneyStore.form.paymentMethod,
                location: SEGMENT_LOCATIONS.SEND_MONEY,
                failure: ex,
              },
            })
            router.push('/send-money/failed')
          }
        }

        nextTick(() => {
          let eventCallCount = 0
          document.getElementById('card_auth_iframe').onload = async (ev) => {
            ++eventCallCount
            if (ev && eventCallCount === 1) {
              cardAuthDialogVisible.value = true
            } else if (ev && eventCallCount >= 2) {
              cardAuthLoading.value = true
              if (ev && eventCallCount === 2) {
                await checkCardAuthResult()
              }
            }
            fadeIn.value = false
          }
          document.getElementById('card_auth_post').submit()
        })
      } else if (missingTransactionInfo === 'IdDocumentAdditionalFields') {
        router.forceRedirect('/send-money/summary/id-update')
      } else if (missingTransactionInfo === 'RequiresIdentityVerification') {
        router.forceRedirect('/send-money/summary/verify-identity-ekyc')
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_FAILED,
          traits: {
            paymentType: sendMoneyStore.form.paymentMethod,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            failure: missingTransactionInfo,
          },
        })
        router.replace('/send-money/failed')
      }
    }

    const hideCardAuthDialog = () => {
      cardAuthDialog.value = false
      router.forceRedirect('/send-money/summary')
    }

    const mount3DSContainer = (authContainer, callback = null) => {
      if (!authContainer) {
        authContainer = document.createElement('div')
      }

      // wait some 100ms so Vue can render the elements (waiting one frame with requestAnimationFrame seems to be not always enough)
      nextTick(() => {
        const div = document.getElementById('card_auth_container')
        div.innerHTML = ''
        div.appendChild(authContainer)
        div.style.display = 'flex'
        cardAuthDialogVisible.value = true
        fadeIn.value = false

        // run the callback if not null
        if (callback) {
          callback()
        }

        window.setTimeout(() => {
          cardAuthorizationLoaded.value = true
        }, 1000)
      })
    }
    const paymentMethod = sendMoneyStore.form.paymentMethod

    const create = async () => {
      const appStore = useAppStore()
      try {
        if (
          (paymentMethod === PaymentMethod.CreditCard.enumName ||
            paymentMethod === PaymentMethod.DebitCard.enumName) &&
          !isO2OContract
        ) {
          let auth = cardAccount.getCardTransactionResult
          const cardApiV2 = cardAccount.useApexx || cardAccount.useAdyen
          const isQuoteExpired = sendMoneyStore.quoteExpired
          const transferLog = sendMoneyStore.transfer
          //if is apexx we need to re - startCardTransaction even if we already have it
          //not applicable for cybersource as they use different APIs
          if (
            !auth ||
            cardApiV2 ||
            isQuoteExpired ||
            (!transferLog.error && transferLog.stage === TRANSFER.STAGES.EDD)
          ) {
            //get paymntMethod id
            // if that id is not present in paymentMethods
            // = new card
            const paymentMeethods = paymentsStore.allPaymentMethods
            const paymentMethodId = sendMoneyStore.form.paymentMethodId
            const paymentMethodMatch = paymentMeethods.find((pm) => pm.id == paymentMethodId)

            let isNewCard = !paymentMethodMatch

            // 3DSV2
            auth = await cardAccount.startCardTransaction(
              sendMoneyStore.form.cardSecurityCode,
              isNewCard
            )
          }

          if (auth.data && !auth.data.requirePayerAuthentication) {
            await orderCreate()
          } else if (
            auth.data &&
            auth.data.requirePayerAuthentication &&
            auth.data.payerAuthentication?.provider === PAYMENT_GATEWAY.ADYEN &&
            adyenComposable
          ) {
            await adyenComposable.init()
            adyenComposable.state.onSuccessCallback = async () => {
              cardAuthDialogVisible.value = false
              cardAuthDialog.value = false
              fadeIn.value = true

              await analyticsStore.track({
                event: SEGMENT_EVENTS.CARD_AUTHORISATION_COMPLETED,
                traits: {
                  paymentType: sendMoneyStore.form.paymentMethod,
                  newCard: cardAccount.getIsNewCard(),
                  cardBrand: selectedPaymentMethod.cardBrand,
                  cardBillingCountry: selectedPaymentMethod.billingCountry,
                  authorisationType: threeDSActionType.value,
                  paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
                  location: SEGMENT_LOCATIONS.SEND_MONEY,
                },
              })

              await orderCreate()
            }

            adyenComposable.state.onAuthRequired = startAdyen3Ds

            adyenComposable.state.onAuthError = async (ex) => {
              await analyticsStore.track({
                event: SEGMENT_EVENTS.CARD_AUTHORISATION_FAILED,
                traits: {
                  paymentType: sendMoneyStore.form.paymentMethod,
                  newCard: cardAccount.getIsNewCard(),
                  cardBrand: selectedPaymentMethod.type,
                  cardBillingCountry: selectedPaymentMethod.billingCountry,
                  authorisationType: threeDSActionType.value,
                  paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
                  location: SEGMENT_LOCATIONS.SEND_MONEY,
                },
              })

              onError(ex)
            }

            return await startAdyen3Ds(auth.data.payerAuthentication?.action)
          } else if (auth.payerAuthenticationAccessToken && auth.payerAuthenticationReferenceId) {
            const authContainer = document.createElement('div')

            await analyticsStore.track({
              event: SEGMENT_EVENTS.CARD_AUTHORISATION_STARTED,
              traits: {
                paymentType: sendMoneyStore.form.paymentMethod,
                newCard: cardAccount.getIsNewCard(),
                cardBrand: selectedPaymentMethod.cardBrand,
                cardBillingCountry: selectedPaymentMethod.billingCountry,
                paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
                location: SEGMENT_LOCATIONS.SEND_MONEY,
              },
            })

            const result = await cardAccount.authorize3DSV2({
              authContainer: authContainer,
              accessToken: auth.payerAuthenticationAccessToken,
              referenceId: auth.payerAuthenticationReferenceId,
              encryptedCvn: auth.encryptedCvn,
              showCardAuth: () => {
                cardAuthorizationLoaded.value = false
                cardAuthDialog.value = true
                mount3DSContainer(authContainer)
              },
              finishCardAuth: async () => {
                authenticated = true
                cardAuthDialog.value = false

                await analyticsStore.track({
                  event: SEGMENT_EVENTS.CARD_AUTHORISATION_COMPLETED,
                  traits: {
                    paymentType: sendMoneyStore.form.paymentMethod,
                    newCard: cardAccount.getIsNewCard(),
                    cardBrand: selectedPaymentMethod.cardBrand,
                    cardBillingCountry: selectedPaymentMethod.billingCountry,
                    authorisationType: threeDSActionType.value,
                    paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
                    location: SEGMENT_LOCATIONS.SEND_MONEY,
                  },
                })
                await orderCreate()
              },
              cancelCardAuth: () => {
                hideCardAuthDialog()
              },
              loadingState: fadeIn,
            })

            if (!result) {
              appStore.logError('Authorization 3DSV2 failed', 'OrderCreation', result)
              analyticsStore.track({
                event: SEGMENT_EVENTS.PAYMENT_FAILED,
                traits: {
                  paymentType: sendMoneyStore.form.paymentMethod,
                  location: SEGMENT_LOCATIONS.SEND_MONEY,
                  failure: 'Authorization 3DSV2 failed',
                },
              })
              router.replace('/send-money/failed')
            }
          } else {
            appStore.logError(
              'Missing payer authentication data in validate order',
              'OrderCreation'
            )
            analyticsStore.track({
              event: SEGMENT_EVENTS.PAYMENT_FAILED,
              traits: {
                paymentType: sendMoneyStore.form.paymentMethod,
                location: SEGMENT_LOCATIONS.SEND_MONEY,
                failure: 'Missing payer authentication data in validate order',
              },
            })
            router.replace('/send-money/failed')
          }
        } else {
          await orderCreate()
        }
      } catch (ex) {
        onError(ex)
      }
    }

    const onError = (ex) => {
      if (ex?.errorCode) {
        const { errorCode } = ex

        sendMoneyStore.setFailedTransferData({ transferError: errorCode, stage: 'Edd' })

        if (errorCode === 'QUOTE021') {
          sendMoneyStore.quoteExpired = true
          let eventName = PAYMENT_METHODS.OPEN_BANKING

          switch (sendMoneyStore.form.paymentMethod) {
            case PAYMENT_METHODS.OPEN_BANKING:
              eventName = SEGMENT_EVENTS.OPEN_BANKING_QUOTE_EXPIRED
              break
            case PAYMENT_METHODS.DEBIT_CARD:
            case PAYMENT_METHODS.CREDIT_CARD:
              eventName = SEGMENT_EVENTS.CARD_QUOTE_EXPIRED
              break
          }

          analyticsStore.track({
            event: eventName,
            traits: {
              accountType: authStore.user.customer.accountType,
            },
          })

          appStore.logException('Exception during order creation: Quote Expired', ex)
        } else {
          sendMoneyStore.quoteExpired = false
        }
      }

      // check if it failed due to Payment Stopped (Accertify) error
      if (ex?.message != TRANSFER.RESPONSE.VERIFIED.STOP) {
        sendMoneyStore.isPaymentMethodError = true
      } else if (ex?.message === TRANSFER.RESPONSE.VERIFIED.STOP) {
        appStore.logException('Exception during order creation - Payment Stopped', ex)
      }
      appStore.logException('Exception during order validation', ex)

      if (sendMoneyStore.isPaymentMethodError) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_FAILED,
          traits: {
            paymentType: sendMoneyStore.form.paymentMethod,
            errorCode: ex.errorCode,
            errorDesc: ex.errorMessage,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
      }

      router.replace('/send-money/failed')
    }

    const startAdyen3Ds = async (action) => {
      threeDSActionType.value = action.type

      if (!adyenComposable) {
        useAppStore().logError('Adyen not initialized.')
      }

      await analyticsStore.track({
        event: SEGMENT_EVENTS.CARD_AUTHORISATION_STARTED,
        traits: {
          paymentType: sendMoneyStore.form.paymentMethod,
          newCard: cardAccount.getIsNewCard(),
          cardBrand: selectedPaymentMethod.cardBrand,
          cardBillingCountry: selectedPaymentMethod.billingCountry,
          authorisationType: action.type,
          paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })

      if (action.type === 'redirect') {
        adyenComposable.addAdyenTabListener()
      }

      if (!cardAuthDialogVisible.value) {
        cardAuthDialogVisible.value = true
        cardAuthDialog.value = true
      }

      function callback() {
        adyenComposable.mountFromAction(action, '#card_auth_container')
      }

      mount3DSContainer(null, callback)
    }

    onBeforeMount(async () => {
      const previousRoute = appStore.getCurrentRoute.from.name
      const isActiveStep = sendMoneyStore.activeStepIdx
      if (useSendMoneyStore().getCardPaymentAvailable && useCardAccountStore().useAdyen) {
        adyenComposable = useAdyen()
      }
      if (previousRoute === 'SendMoneyFinished') {
        router.push('/Activity')
      } else {
        if (sendMoneyStore.isVerifiedFromConfirmStage) {
          await orderCreate()
        } else {
          await create()
        }
      }
    })

    onMounted(() => {
      addTabListener(orderCreate)
    })

    onUnmounted(() => {
      closeBroadcastChannel()
      adyenComposable?.closeAdyenBroadcastChannel()
    })

    return {
      threatMetrixSrc,
      cardAuthDialog,
      cardAuthFields,
      cardAuthDialogVisible,
      cardAuthLoading,
      cardAuthUrl,
      hideCardAuthDialog,
      cardAuthorizationLoaded,
      fadeIn,
      isO2OContract,
      isOpenBanking,
      senderCountry,
      voltCheckoutComplete,
      $t,
      isPayTo,
      routeFrom,
      adyenHosted3dsLoading: computed(() => adyenComposable?.state.hosted3dsLoading),
      threeDSActionType,
    }
  },
}
</script>

<style scoped>
.creating-img {
  ::v-deep .card-image-title-image {
    @apply animate-float;
  }
}

/* TODO IMPROVE */
.threatmetrix {
  display: none;
}

.card-auth-iframe {
  width: 100%;
  height: 100%;
  min-height: 60vh;
  border: 0px;
}

.card-auth-dialog {
  display: none;
  filter: blur(0px);
  opacity: 1;
  transition: 300ms linear filter, 300ms linear opacity;

  ::v-deep .overlay-content {
    max-width: 100%;

    @screen sm {
      max-width: 80vw;
    }
  }

  ::v-deep .card-content {
    margin: 0px;
    padding: 0px;
  }
}

.card-auth-dialog-visible {
  @apply flex;
}

.card-auth-dialog-blurred {
  filter: blur(3px);
}

#card_auth_container {
  min-height: 500px;
  display: block;
  justify-content: center;
}

.loading-container {
  @apply flex justify-center mb-6;
  margin-top: 100px;
  @screen smL {
    margin-top: 0px;
  }
}

.overlay-container {
  @apply flex justify-center items-center w-13 h-13;
}

.overlay-container > img,
svg {
  @apply absolute;
}

.overlay-container > .loading-spinner {
  @apply w-auto;
}

::v-deep .overlay-container > .loading-spinner > .loading-spinner-container > svg {
  width: 5rem;
  height: 5rem;
}

.open-banking-warning {
  max-width: 28rem;
  width: 100%;
  position: absolute;
  top: 0;

  @screen sm {
    margin-top: 100px;
  }
  p {
    color: #774913;
  }
}
.message-icon {
  @apply inline-block;
  @apply w-5;
  @apply h-5;
}
</style>

<style>
#Cardinal-CCA-IFrame {
  height: 100% !important;
}
</style>
